import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Title } from "../../components/library/Text";

const Wrapper = styled.div`
  width: 100%;
  max-width: 1230px;
  padding: 0 15px;
  margin: 141px auto 93px;

  @media screen and (max-width: 768px) {
    margin: 90px auto 68px;
  }
`;

const StepTitle = styled(Title)`
  text-align: center;
  color: #070d0f;
  margin: 0;
`;

const InstructionsWrapper = styled.div`
  overflow-x: hidden;
`;

const InstructionBox = styled.div.attrs(() => ({
  "data-aos": "fade"
}))`
  @media screen and (min-width: 769px) {
    :nth-child(odd) {
      .wavy-instruction-text {
        right: 0;
      }
    }

    :nth-child(even) {
      .wavy-instruction-text {
        left: 0;
      }
    }
  }
`;

const Instructions = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 4px;
  }

  @media screen and (min-width: 769px) {
    margin-bottom: 50px;
    justify-content: center;
  }
`;

const Number = styled.div`
  font-family: Quarto;
  font-style: normal;
  font-weight: 400;
  font-size: 165px;
  line-height: 1.2;

  @media screen and (max-width: 768px) {
    font-size: 135px;
    margin-right: 48px;
    margin-left: 20px;
  }
`;

const BigNumber = styled(Number)`
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const InstructionTextWrapper = styled.div.attrs(() => ({
  className: "wavy-instruction-text"
}))`
  @media screen and (min-width: 769px) {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    padding: 0 5%;
  }
`;

const InstructionText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-width: 319px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 25px;
  }
`;

const Vid = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;

  img,
  video,
  video[poster] {
    width: 100%;
    max-height: 500px;
  }

  video {
    z-index: -1;
  }

  img {
    opacity: 0;
    margin: 0;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 768px) {
    img,
    video,
    video[poster] {
      max-height: 236.61px;
    }
  }
`;

const VideoBox = ({ src }: { src: string }) => {
  const imgRef = useRef<HTMLImageElement>();
  const baseUrl = "https://static.wavyformula.com";

  const onLoad = () => {
    imgRef.current.style.opacity = "1";
  };

  useEffect(() => {
    imgRef.current.src = imgRef.current.dataset.src;
  }, []);

  return (
    <Vid>
      <video autoPlay loop muted playsInline poster={`${baseUrl}/images/${src}-frame.gif`}>
        <source src={`${baseUrl}/videos/${src}-opt.webm`} type="video/webm" />
        <source src={`${baseUrl}/videos/${src}-opt.mp4`} type="video/mp4" />
      </video>
      <img ref={imgRef} onLoad={onLoad} data-src={`${baseUrl}/images/${src}-opt.gif`} />
    </Vid>
  );
};

const Steps = () => (
  <Wrapper>
    <StepTitle>How Wavy Blue Works</StepTitle>
    <InstructionsWrapper>
      <InstructionBox>
        <Instructions>
          <Number>1</Number>
          <InstructionTextWrapper>
            <InstructionText>Dampen your hair.</InstructionText>
          </InstructionTextWrapper>
        </Instructions>
        <VideoBox src="wet-hair" />
      </InstructionBox>
      <InstructionBox>
        <Instructions>
          <Number>2</Number>
          <InstructionTextWrapper>
            <InstructionText>Grab a dime-sized amount of product into your palm.</InstructionText>
          </InstructionTextWrapper>
        </Instructions>
        <VideoBox src="apply-product" />
      </InstructionBox>
      <InstructionBox>
        <Instructions>
          <BigNumber>3</BigNumber>
          <InstructionTextWrapper>
            <InstructionText>Work through dampened hair</InstructionText>
          </InstructionTextWrapper>
        </Instructions>
        <VideoBox src="work-through-hair" />
      </InstructionBox>
      <InstructionBox>
        <Instructions>
          <BigNumber>4</BigNumber>
          <InstructionTextWrapper>
            <InstructionText>
              Brush or curl sponge like normal. <b>(if that’s your wave)</b>
            </InstructionText>
          </InstructionTextWrapper>
        </Instructions>
        <VideoBox src="brush-hair" />
      </InstructionBox>
    </InstructionsWrapper>
  </Wrapper>
);

export default Steps;
