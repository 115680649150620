import React from "react";
import styled from "styled-components";
import { Arrow, Wave } from "../../components/library/Icons";
import { Header } from "../../components/library/Text";
import Ocean from "../../components/ocean/Ocean";
import { ImageConstants } from "../../constants/Images";
import { getFileParts } from "../../utils/browser";

const { base, extension } = getFileParts(ImageConstants.WASH);
const mobileBackground = `${base}@1200w.${extension}`;
const desktopBackground = `${base}@2100w.${extension}`;

const Section = styled.section`
  position: relative;

  ::after {
    content: "";
    height: calc(100% + 70px);
    width: 100%;
    position: absolute;
    top: -70px;
    left: 0;
    background-color: #c4c4c4;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(${desktopBackground}) 0 0 / cover no-repeat;
    filter: grayscale(100%);
    z-index: -1;
  }

  @media screen and (max-width: 768px) {
    height: 522px;

    ::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
        url(${mobileBackground}) 0 0 / cover no-repeat;
      background-position-x: 20%;
    }
  }
`;

const Content = styled.div`
  max-width: 768px;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    height: 100%;
  }
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 195px 0;

  @media screen and (max-width: 768px) {
    padding: 75px 0;
    height: 100%;
  }
`;

const WaveContainer = styled.div`
  margin: 0 auto 28px;
`;

const SmallWave = styled(Wave)`
  display: block;
`;

const HeaderText = styled(Header)`
  margin-bottom: 39px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    margin: 0;
    padding: 0 20px;
  }
`;

const SmallText = styled.div`
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ArrowContainer = styled.div`
  z-index: 1;
  position: absolute;
  bottom: -90px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 768px) {
    bottom: -80px;
  }
`;

const OceanWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;

  @media screen and (max-width: 768px) {
    width: calc(100% + 15px);
    left: -15px;
  }
`;

const LandingSection = () => (
  <Section>
    <Content>
      <Information>
        <WaveContainer>
          <SmallWave />
          <SmallWave />
        </WaveContainer>
        <HeaderText>Ready in 3, 2, 1…</HeaderText>
        <SmallText>We recommend using our Curl & Coil Balm in three easy steps:</SmallText>
      </Information>
    </Content>
    <ArrowContainer>
      <Arrow color="#7ad4ff" />
    </ArrowContainer>
    <OceanWrapper>
      <Ocean />
    </OceanWrapper>
  </Section>
);

export default LandingSection;
