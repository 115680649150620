import React, { useState } from "react";
import styled from "styled-components";
import { MultiSizeImage } from "../../components/library/Images";
import LargeCarousel from "../../components/library/LargeCarousel";
import { ImageConstants } from "../../constants/Images";

const Wrapper = styled.div`
  position: relative;
`;

const BarberCarousel = styled(LargeCarousel)`
  position: relative;

  .carousel-title,
  .carousel-sub-quote,
  .carousel-caption-text {
    font-family: "Gill Sans";
  }

  .carousel-title {
    margin-bottom: 12px;
  }

  .carousel-quote {
    margin: 0 auto 30px auto;
    font-weight: 700;
  }

  .carousel-sub-quote {
    font-size: 20px;
    line-height: 30px;
    margin: 0 auto 73px auto;
  }

  .carousel-item {
    height: 655px;
  }

  .carousel-caption {
    padding-top: 100px;
  }

  .carousel-indicators {
    bottom: 121px;
  }

  ::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }

  ::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    z-index: -1;
    filter: grayscale(100%);
    background-image: url(${ImageConstants.BARBER});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 30%;
  }

  @media screen and (max-width: 768px) {
    .carousel-caption {
      padding-top: 65px;
    }

    .carousel-inner-content {
      .carousel-quote,
      .carousel-title {
        margin-bottom: 23px;
      }

      .carousel-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2;
      }

      .carousel-sub-quote {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 39px;
      }
    }
  }
`;

const Circle = styled(MultiSizeImage)`
  width: 182px;
  height: 182px;
  position: absolute;
  left: 50%;
  bottom: -91px;
  transform: translateX(-50%);
  border: 2px solid white;
  border-radius: 50%;
  background: #0e0e0e;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    width: 96px;
    height: 96px;
    bottom: -48px;
  }
`;

const items = [
  {
    location: ImageConstants.BOX,
    src: ImageConstants.BARBER,
    title: "Shop Talk",
    altText: "Slide 1",
    caption: (
      <>
        <b>V Dott</b> | Philly Cuts
      </>
    ),
    content: "Real Talk With Your Favorite Barbers",
    subQuote:
      "“My clients have been asking me for the best hair and beard conditioner for years. With the Curl & Coil Balm, I finally have something to stand behind.”",
    key: "1"
  },
  {
    location: ImageConstants.BOX,
    src: ImageConstants.BARBER,
    title: "Shop Talk",
    altText: "Slide 2",
    caption: (
      <>
        <b>Darrell</b> | Matt's Barber Shop
      </>
    ),
    content: "Real Talk With Your Favorite Barbers",
    subQuote:
      "“The Curl & Coil Balm is incredibly easy to use. It's the perfect product to use that leaves my clients looking & feeling fresh.”",
    key: "2"
  },
  {
    location: ImageConstants.BOX,
    src: ImageConstants.BARBER,
    title: "Shop Talk",
    altText: "Slide 3",
    caption: (
      <>
        <b>Dorsey</b> | Jackson's Barbershop
      </>
    ),
    content: "Real Talk With Your Favorite Barbers",
    subQuote:
      "“Can't recommend the Curl & Coil Balm enough to fellow barbers. It adds just the right level of moisture after a fresh cut, whether I'm doing a fade, custom hair designs, or trimming beards.”",
    key: "3"
  }
];

const BarberTestimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onSelect = (newIndex: number) => setActiveIndex(newIndex);

  return (
    <Wrapper>
      <BarberCarousel oneImage items={items} index={activeIndex} onSelect={onSelect} />
      <Circle src={items[activeIndex].location} />
    </Wrapper>
  );
};

export default BarberTestimonials;
