import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/library/Button";
import Input from "../../components/library/Input";
import { SubHeader, Title } from "../../components/library/Text";
import useAlert from "../../hooks/useAlert";
import WavyApi from "../../utils/api";
import { validateEmail } from "../../utils/form";
import Tracking from "../../utils/tracking";

const Wrapper = styled.div`
  text-align: center;
  padding: 104px 0px 99px;
  background: linear-gradient(180deg, #e4f6ff 0%, #e4f6ff 70.8%, #fff 70.8%, #fff 100%);

  @media screen and (max-width: 768px) {
    padding: 47px 20px 69px;

    background: linear-gradient(180deg, #e4f6ff 0%, #e4f6ff 43.5%, #fff 43.5%, #fff 100%);
  }
`;

const BaseTitle = styled(Title)`
  margin-bottom: 12px;
  font-size: 22px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
    color: #070d0f;
  }
`;

const BaseHeader = styled(SubHeader)`
  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 43px;
    margin-bottom: 35px;
  }
`;

const Info = styled.p`
  margin-bottom: 71px;
  @media screen and (max-width: 768px) {
    margin-bottom: 35px;
  }
`;

const Card = styled.div`
  max-width: 930px;
  margin: 0 auto;
  background: #fbfbfb;
  box-shadow: 4px 4px 4px rgba(45, 112, 149, 0.1);

  @media screen and (min-width: 769px) and (max-width: 970px) {
    margin: 0 20px;
  }
`;

const CardContent = styled.div`
  width: 100%;
  max-width: 617px;
  margin: 0 auto;
  padding: 73px 15px 44px;

  @media screen and (max-width: 681px) {
    padding: 37px 32px;
  }
`;

const BaseInput = styled(Input)`
  input,
  textarea {
    border-bottom-color: #d3d3d3;
    font-size: 20px;

    ::placeholder {
      color: #0e0e0e;
    }

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;

const ContactInput = styled(BaseInput)`
  margin-bottom: calc(18px - 0.375rem);

  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const Message = styled(BaseInput)`
  margin-bottom: 45px;

  @media screen and (max-width: 768px) {
    margin-bottom: 28px;
  }
`;

const ContactUs = () => {
  // hooks
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const Alerts = useAlert();

  // functions
  const onMessageChange = () => {
    // update the rows
    const { scrollHeight, clientHeight, rows: rowsString } = messageRef.current;
    const rows = parseInt(rowsString);
    if (clientHeight < scrollHeight) {
      messageRef.current.rows = rows + 1;
    }
  };

  const onSubmit = () => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;
    if (!name) {
      Alerts.showWarning("Please enter a name");
      return;
    }

    if (!validateEmail(email)) {
      Alerts.showWarning("Please enter a valid email");
      return;
    }

    Tracking.fb("Contact");
    setLoading(true);
    WavyApi.contactUs(name, email, message)
      .then(res => {
        setLoading(false);
        Alerts.showSuccess(res);
      })
      .catch(err => {
        setLoading(false);
        Alerts.showError(err);
      });
  };

  return (
    <Wrapper>
      <BaseTitle>What's on your mind?</BaseTitle>
      <BaseHeader>Contact Us</BaseHeader>
      <Info>
        Don’t think twice about reaching out with any question, feedback, praise, or whatever is on
        your mind...and a real person will get back to you quickly.
      </Info>
      <Card>
        <CardContent>
          <ContactInput placeholder="Name" inputRef={nameRef} />
          <ContactInput type="email" placeholder="Email Address" inputRef={emailRef} />
          <Message
            type="textarea"
            rows="1"
            placeholder="Message"
            inputRef={messageRef}
            onChange={onMessageChange}
          />
          <Button width={235} onClick={onSubmit} disabled={loading}>
            Send Message
          </Button>
        </CardContent>
      </Card>
    </Wrapper>
  );
};

export default ContactUs;
