import { useCallback, useContext, useMemo } from "react";
import { AlertContext, AlertProviderProps } from "../contexts/AlertContext";

const useAlert = () => {
  const alertContext = useContext(AlertContext) as AlertProviderProps;
  const { dispatch } = alertContext;

  const showMessage = useCallback(
    (content, duration = 3000, alertType = "info") => {
      dispatch({ type: "showAlert", content, duration, alertType });
    },
    [dispatch]
  );

  const showInfo = useCallback(
    (content, duration = 3000) => showMessage(content, duration, "info"),
    [showMessage]
  );

  const showWarning = useCallback(
    (content, duration = 3000) => showMessage(content, duration, "warning"),
    [showMessage]
  );

  const showSuccess = useCallback(
    (content, duration = 3000) => showMessage(content, duration, "success"),
    [showMessage]
  );

  const showError = useCallback(
    (content, duration = 3000) => showMessage(content, duration, "danger"),
    [showMessage]
  );

  return useMemo(() => {
    class Alerts {
      static showMessage = showMessage;
      static showInfo = showInfo;
      static showWarning = showWarning;
      static showSuccess = showSuccess;
      static showError = showError;
    }

    return Alerts;
  }, [showMessage, showInfo, showWarning, showSuccess, showError]);
};

export default useAlert;
