import React from "react";
import styled from "styled-components";
import Collapser from "../../components/library/Collapser";
import { Title, SubHeader } from "../../components/library/Text";

const Wrapper = styled.div`
  max-width: 766px;
  padding: 0 15px;
  margin: 111px auto 99px;

  @media screen and (max-width: 768px) {
    margin: 65px 0 67px;
  }
`;

const TextBox = styled.div`
  @media screen and (min-width: 769px) {
    text-align: center;
  }
`;

const BaseTitle = styled(Title)`
  margin-bottom: 22px;
`;

const BaseHeader = styled(SubHeader)`
  margin-bottom: 82px;

  @media screen and (max-width: 768px) {
    margin-bottom: 53px;
    font-size: 36px;
  }
`;

const items = [
  {
    key: 7,
    text: "How often can I use the Curl & Coil Balm?",
    content:
      "You can use it as often as you like. We recommend at night before you go to sleep and in the morning before you leave out the door",
    isOpen: false
  },
  {
    key: 8,
    text: "Is the Curl & Coil Balm better for the hair on my head or beard?",
    content: "The Curl & Coil Balm works great on both.",
    isOpen: false
  },
  {
    key: 9,
    text: "How much product should I use when styling my hair?",
    content: "We recommend a dime-sized amount for each use, a little bit goes a very long way.",
    isOpen: false
  },
  {
    key: 10,
    text: "Can I use the Curl & Coil Balm with other products?",
    content:
      "Absolutely. We recommend putting the Curl & Coil Balm on first and then proceeding with the rest of your hair care routine.",
    isOpen: false
  }
];

const FaqSection = () => (
  <Wrapper>
    <TextBox>
      <BaseTitle>You've got questions</BaseTitle>
      <BaseHeader>We've Got Answers</BaseHeader>
    </TextBox>
    <Collapser items={items} />
  </Wrapper>
);

export default FaqSection;
