import React from "react";
import styled from "styled-components";

const StyledOcean = styled.svg`
  position: relative;
  width: 100%;
  height: 70px;
  will-change: transform;

  .parallax > use {
    animation: wave 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }

  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }

  @keyframes wave {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
`;

const Ocean = React.memo(({ color = "white" }: { color?: string }) => (
  <StyledOcean
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
    shapeRendering="auto"
  >
    <defs>
      <path
        id="gentle-wave"
        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
      />
    </defs>
    <g className="parallax">
      <use xlinkHref="#gentle-wave" x="48" y="0" fill={color} />
    </g>
  </StyledOcean>
));

export default Ocean;
