import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import BarberTestimonials from "../routes/instructions/BarberTestimonials";
import ContactUs from "../routes/instructions/ContactUs";
import FaqSection from "../routes/instructions/FaqSection";
import LandingSection from "../routes/instructions/LandingSection";
import Steps from "../routes/instructions/Steps";

const InstructionsPage = () => (
  <Layout>
    <Seo title="Instructions" />
    <LandingSection />
    <Steps />
    <BarberTestimonials />
    <FaqSection />
    <ContactUs />
  </Layout>
);

export default InstructionsPage;
