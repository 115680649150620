import React, { useCallback, useRef, useState } from "react";
import { Carousel, CarouselIndicators, CarouselItem } from "reactstrap";
import styled from "styled-components";

interface LargeCarouselProps {
  items: any[];
  className?: string;
  index?: number;
  onSelect?: (index?: number) => void;
  hideIndicators?: boolean;
  oneImage?: boolean;
}

const Item = styled(CarouselItem)`
  height: 696px;

  @media screen and (max-width: 768px) {
    height: 461px;
  }
`;

const Indicators = styled(CarouselIndicators)`
  bottom: 55px;
  margin: 0 15%;

  li {
    margin: 0 21px;
    width: 14px;
    height: 14px;
    opacity: 0.4;
    border-radius: 50%;
  }

  @media screen and (max-width: 768px) {
    bottom: 35px;
  }
`;

const ImageContainer = styled.div.attrs(() => ({
  className: "carousel-image"
}))`
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;

  ::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
`;

const Content = styled.div.attrs(() => ({
  className: "carousel-caption"
}))`
  padding: 0;
  height: 100%;
  position: static;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerContent = styled.div.attrs(() => ({
  className: "carousel-inner-content"
}))`
  max-width: 1230px;
  padding: 0 15px;
`;

const Quote = styled.div.attrs(() => ({
  className: "carousel-quote"
}))`
  font-family: Quarto;
  font-size: 56px;
  line-height: 1.2;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
`;

const SubQuote = styled.div.attrs(() => ({
  className: "carousel-sub-quote"
}))`
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
`;

const Caption = styled.p.attrs(() => ({
  className: "carousel-caption-text"
}))`
  margin: 0;
  font-size: 22px;
  line-height: 25px;
  color: #e4f6ff;
`;

const Title = styled.div.attrs(() => ({
  className: "carousel-title"
}))`
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  color: white;
`;

const LargeCarousel = ({
  items,
  className,
  index,
  onSelect,
  oneImage = false,
  hideIndicators = false
}: LargeCarouselProps) => {
  // hooks
  const animatingRef = useRef(false);
  const activeIndexRef = useRef(index !== undefined ? index : 0);
  const [uncontrolledIndex, setUncontrolledIndex] = useState(index || 0);

  // variables
  // if index is provided, the carousel is 'controlled'
  const isControlled = index !== undefined;
  const activeIndex = isControlled ? index : uncontrolledIndex;

  // functions
  const next = useCallback(() => {
    if (animatingRef.current) {
      return;
    }

    // update active index
    activeIndexRef.current = (activeIndexRef.current + 1) % items.length;

    // update index state
    if (isControlled) {
      if (onSelect) onSelect(activeIndexRef.current);
    } else {
      setUncontrolledIndex(activeIndexRef.current);
    }
  }, [items, isControlled, onSelect]);

  const previous = useCallback(() => {
    if (animatingRef.current) {
      return;
    }

    // update active index
    activeIndexRef.current = (activeIndexRef.current - 1 + items.length) % items.length;

    // update index state
    if (isControlled) {
      if (onSelect) onSelect(activeIndexRef.current);
    } else {
      setUncontrolledIndex(activeIndexRef.current);
    }
  }, [items, isControlled, onSelect]);

  const goToIndex = useCallback(
    newIndex => {
      if (animatingRef.current) {
        return;
      }

      // update active index
      if (isControlled) {
        if (onSelect) onSelect(newIndex);
      } else {
        setUncontrolledIndex(newIndex);
      }
    },
    [isControlled, onSelect]
  );

  const onExiting = useCallback(() => (animatingRef.current = true), []);
  const onExited = useCallback(() => (animatingRef.current = false), []);

  return (
    <Carousel
      interval={false}
      className={className}
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      {items.map(item => (
        <Item key={item.key} onExiting={onExiting} onExited={onExited}>
          {oneImage ? null : (
            <ImageContainer>
              <Image src={item.src} alt={item.altText} style={item.imgStyle} />
            </ImageContainer>
          )}
          <Content>
            <InnerContent>
              {item.title ? <Title>{item.title}</Title> : null}
              {item.content ? <Quote>{item.content}</Quote> : null}
              {item.subQuote ? <SubQuote>{item.subQuote}</SubQuote> : null}
              {item.caption ? <Caption>{item.caption}</Caption> : null}
            </InnerContent>
          </Content>
        </Item>
      ))}
      {hideIndicators ? null : (
        <Indicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      )}
    </Carousel>
  );
};

export default LargeCarousel;
